import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StoryPage from './StoryPage';
import BookReader from './BookReader'; // Импортируйте ваш компонент BookReader
import ScrollToTop from './ScrollToTop'; // Импортируйте ScrollToTop

const App = () => {
    return (
        <Router>
            <ScrollToTop /> {/* Добавьте компонент ScrollToTop здесь */}
            <Routes>
                <Route path="/" element={<StoryPage />} />
                <Route path="/book-reader" element={<BookReader />} /> {/* Добавьте маршрут для BookReader */}
            </Routes>
        </Router>
    );
};

export default App;
