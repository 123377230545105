import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируйте useNavigate
import './StoryPage.css'; // Импорт CSS

const StoryPage = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate(); // Используем useNavigate

    const descriptionText = [
        "Ссория всегда заботится о своих гражданах так, как считает нужным. Поэтому, если они не хотят закончить от выстрела в затылок, стоя на главной площади, то предпочитают не хранить секреты от любимой страны. \n",
        "У Морены нет тайн от Родины и есть планы на жизнь: успешно сдать выпускной экзамен, стать примерной женой и, если позволят, врачом. Она ошибается лишь раз, выбирая себе в подруги Ватру, секретов которой хватает на обеих. Обманом та втягивает Мору в свой мир, и на обратной стороне Ссории обнаруживаются не только тату-салоны и покерные турниры, но и группа людей, объединенная идеей свергнуть действующий режим. Сопротивление. Морена остается в ужасе от возможных последствий ее новых знакомств, одним из которых становится отброс Касьян. У Каса нет номера жителя Ссории и желания стать частью приличного общества, зато есть бездушная ухмылка и необъяснимая тяга испортить Море жизнь. \n",
        "Мора пытается вернуться к прежней жизни, и их пути больше не должны пересечься. Но что-то идет не по плану, и на самый важный экзамен своей жизни она отправляется с привязанной к бедру бомбой…"
    ];

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    const handleReadButtonClick = () => {
        navigate('/book-reader'); // Убедитесь, что путь соответствует маршруту в App.js
    };

    return (
        <div className="story-container">
            {/* Обложка */}
            <div className="cover-image">
                <img src="/IMG_3789.PNG" alt="Ссория-2067" />
                <div className="text-overlay">
                    <h1 className="title">Ссория-2067</h1>
                    <h2 className="genre">АНТИУТОПИЯ</h2>
                </div>
            </div>

            {/* Линия-разрыв под изображением */}
            <div className="separator"></div>

            {/* Тематики */}
            <div className="tags">
                <span>· Глобальные катастрофы</span>
                <span>· Любовь/ненависть</span>
                <span>· Насилие</span>
                <span>· Политические интриги</span>
                <span>· Реализм</span>
                <span>· Серая мораль</span>
                <span>· Украина</span>
                <span>· 18+</span>
            </div>

            {/* Описание */}
            <div className="description">
                {!isExpanded ? (
                    <div>
                        <p>
                            {descriptionText[0].slice(0, 154) + '...'}
                        </p>
                        <span
                            className="expand-text"
                            onClick={toggleText}
                            style={{ cursor: 'pointer', color: 'rgb(170,39,49)' }}
                        >
                            Далее
                        </span>
                    </div>
                ) : (
                    <div>
                        {descriptionText.map((text, index) => (
                            <p key={index}>{text}</p>
                        ))}
                        <span
                            className="collapse-text"
                            onClick={toggleText}
                            style={{ cursor: 'pointer', color: 'rgb(170,39,49)' }}
                        >
                            Скрыть
                        </span>
                    </div>
                )}
            </div>

            {/* Кнопка */}
            <button className="read-button" onClick={handleReadButtonClick}>
                Читать
            </button>
        </div>
    );
};

export default StoryPage;
